<template>
  <div >
    <FormulaBanner :title="tableData.chineseName" :formulaCode="formulaCode"/>
    <div class="index">
      <div>
        <div class="top_nav"><span></span>{{tableData.chineseName }}
        </div>
        <div class="formBody">
          <!-- 表单 -->
          <van-form @submit="convert(currentUnit)">
            <div class="form_title">国际单位（推荐）</div>

            <div class="form_card" v-for="item in internationalUnits" :key="item" >
              <div class="form_card_label">
                {{ item }}
              </div>
              <input autocomplete="off" step="0.0001" type="number" class="form_card_input_NEW" :placeholder="item" v-model.number="form[item]" @blur="setCurrentUnit(item)" >
            </div>

            <div class="form_title">常用单位</div>
            <div class="form_card" v-for="item in commonUnits" :key="item" >
              <div class="form_card_label">
                {{ item }}
              </div>
              <input autocomplete="off" step="0.0001" type="number" class="form_card_input_NEW" :placeholder="item" v-model.number="form[item]" @blur="setCurrentUnit(item)" >
            </div>
            <div style="margin: 16px;display: flex;justify-content: center;">
              <van-button style="width:35%;margin-right: 15px" block type="info" native-type="submit">计算</van-button>
              <van-button style="width:35%;border-color: #0091FB;color: #0091FB" block  @click="backBtn">重置</van-button>
            </div>

          </van-form>
        </div>
      </div>

      <div style=" margin-top: 15px;" v-if="tableData.explain && tableData.explain.length > 0">
        <div v-for="(item,index) in tableData.explain" :key="index" class="StandardDescription">
          <div class="top_nav"><span></span>{{ item.name|| item.title}}</div>
          <div style="padding: 2px 10px;background: white" >
            <span v-html="item.setText||item.value">
            </span>
          </div>
        </div>
      </div>
      <FormulaReferences :references-data="tableData.references"></FormulaReferences>

    </div>
  </div>
</template>
<script>
import FormulaBanner from "@/components/FormulaBanner.vue";
import {getAdmins} from "@/utils/adminDate";
import {setById} from "@/service/base";
import {getFormula} from "@/service/BodyMassIndex_api";
import {postDataBrowse} from "@/service/api";
import FormulaReferences from "@/components/template1/FormulaReferences";


export default {
  name: "untilTest",
  components: {FormulaBanner,FormulaReferences},
  data(){
    return{
      formulaCode:'',
      countText: '',
      form:{},
      tableData: {},
      internationalUnits: ['mmol/L', 'μmol/L'],
      commonUnits: ['g/L', 'g/dL', 'g/100mL', 'g%', 'mg/mL'],
      currentUnit: 'mmol/L',
      conversionFactors: {
        'mmol/L': 1,
        'μmol/L': 1000,
        'g/L': 66.458,
        'g/dL': 6.6458,
        'g/100mL': 6.6458,
        'g%': 6.6458,
        'mg/mL': 66.458
      }
    }
  },
  mounted() {
    this.test()
    this.getTableData()
  },
  methods:{
    async test(){
      //像接口头部传用户信息
      let arrays = await getAdmins(this.$route.query.memberId,this.$route.query.channel)
      //点击埋点
      const data={
        "type":"yxgs_dwhs",//index表示在首页列表中点击，mix_index表示在专题列表中点击
        "contentId":Number(this.$route.query.id),//为vod时，根据contentId增加浏览量
        "contentType":'YXGS_DWHS',
        "location":this.$route.query.location,
        "channel":this.$route.query.channel,
        "os":arrays.os,
        "memberId":this.$route.query.memberId,
      }
      await postDataBrowse(data)


      await setById(arrays)
    },




    setCurrentUnit(unit) {
      if (this.form[unit]===null || this.form[unit]===undefined||this.form[unit]===''){
        return
      }

      this.currentUnit = unit;
      this.convert(unit)
    },
    convert(fromUnit) {
      const value = parseFloat(this.form[fromUnit]);
      
      switch (fromUnit) {
        case 'mmol/L':
          this.form['μmol/L'] = this.applyPrecision(value * this.conversionFactors['μmol/L']);
          this.form['g/L'] = this.applyPrecision(value * this.conversionFactors['g/L']);
          this.form['g/dL'] = this.applyPrecision(value * this.conversionFactors['g/dL']);
          this.form['g/100mL'] = this.applyPrecision(value * this.conversionFactors['g/100mL']);
          this.form['g%'] = this.applyPrecision(value * this.conversionFactors['g%']);
          this.form['mg/mL'] = this.applyPrecision(value * this.conversionFactors['mg/mL']);
          break;
        case 'μmol/L':
          this.form['mmol/L'] = this.applyPrecision(value / this.conversionFactors['μmol/L']);
          this.form['g/L'] = this.applyPrecision(value * this.conversionFactors['g/L'] / 1000);
          this.form['g/dL'] = this.applyPrecision(value * this.conversionFactors['g/dL'] / 1000);
          this.form['g/100mL'] = this.applyPrecision(value * this.conversionFactors['g/100mL'] / 1000);
          this.form['g%'] = this.applyPrecision(value * this.conversionFactors['g%'] / 1000);
          this.form['mg/mL'] = this.applyPrecision(value * this.conversionFactors['mg/mL'] / 1000);
          break;
        case 'g/L':
          this.form['mmol/L'] = this.applyPrecision(value / this.conversionFactors['g/L']);
          this.form['μmol/L'] = this.applyPrecision(value * 1000 / this.conversionFactors['g/L']);
          this.form['g/dL'] = this.applyPrecision(value / 10);
          this.form['g/100mL'] = this.applyPrecision(value / 10);
          this.form['g%'] = this.applyPrecision(value / 10);
          this.form['mg/mL'] = this.applyPrecision(value);
          break;
        case 'g/dL':
          this.form['mmol/L'] = this.applyPrecision(value / this.conversionFactors['g/dL']);
          this.form['μmol/L'] = this.applyPrecision(value * 1000 / this.conversionFactors['g/dL']);
          this.form['g/L'] = this.applyPrecision(value * 10);
          this.form['g/100mL'] = this.applyPrecision(value);
          this.form['g%'] = this.applyPrecision(value);
          this.form['mg/mL'] = this.applyPrecision(value * 10);
          break;
        case 'g/100mL':
          this.form['mmol/L'] = this.applyPrecision(value / this.conversionFactors['g/100mL']);
          this.form['μmol/L'] = this.applyPrecision(value * 1000 / this.conversionFactors['g/100mL']);
          this.form['g/L'] = this.applyPrecision(value * 10);
          this.form['g/dL'] = this.applyPrecision(value);
          this.form['g%'] = this.applyPrecision(value);
          this.form['mg/mL'] = this.applyPrecision(value * 10);
          break;
        case 'g%':
          this.form['mmol/L'] = this.applyPrecision(value / this.conversionFactors['g%']);
          this.form['μmol/L'] = this.applyPrecision(value * 1000 / this.conversionFactors['g%']);
          this.form['g/L'] = this.applyPrecision(value * 10);
          this.form['g/dL'] = this.applyPrecision(value);
          this.form['g/100mL'] = this.applyPrecision(value);
          this.form['mg/mL'] = this.applyPrecision(value * 10);
          break;
        case 'mg/mL':
          this.form['mmol/L'] = this.applyPrecision(value / this.conversionFactors['mg/mL']);
          this.form['μmol/L'] = this.applyPrecision(value * 1000 / this.conversionFactors['mg/mL']);
          this.form['g/L'] = this.applyPrecision(value);
          this.form['g/dL'] = this.applyPrecision(value / 10);
          this.form['g/100mL'] = this.applyPrecision(value / 10);
          this.form['g%'] = this.applyPrecision(value / 10);
          break;
        default:
          break;
      }
    },

    applyPrecision(result) {
      // 转换为浮点数
      const floatValue = parseFloat(result);
      // 如果是浮点数且小数位数超过四位则保留四位小数，否则返回原始结果
      if (!isNaN(floatValue) && floatValue % 1 !== 0) { // 检查是否有小数
        const roundedValue = floatValue.toFixed(4); // 四舍五入保留四位小数
        return parseFloat(roundedValue).toString(); // 转回字符串
      }
      return floatValue.toString(); // 返回原始结果字符串
    },
    backBtn(){
      this.form={}
    },
    async getTableData () {
      const data = {
        channel: this.$router.currentRoute.query.channel,
        code: 'OXZOFEBK',
      }
      const res = await getFormula(data.channel,data.code)
      if (res.code === 0) {
        this.tableData = res.data
        this.formulaCode = res.data.code
      }
    },




  },
}
</script>



<style scoped lang="scss">

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.index{
  background: #F6F6F6;
  padding: 10px 15px;
  height: auto;
  min-height: 100vh;
  font-size: 16px;
  padding-bottom: 20px;
  .top_nav{
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom:10px;
    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }
  .formBody{
    background-color: #fff;
    padding:10px;

    .form_title{
      padding: 5px 8px;
      background: #E8F5FF;
    }
    .form_card{
      display: flex;
      justify-content: space-between;
      padding: 5px 8px;
      margin: 10px ;
      align-items: center;
      .form_card_input{
        border-radius: 3px;
        min-height: 25px;
        border: 1px solid #E3E3E3;
      }
    }



    .CalculationResultsBox{
      padding:0 8px;
      .CalculationResults{
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom:10px
      }
      .CalculationDetail{
        font-size: 15px;
        color: #333333;
        // font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom:10px;
        background-color: #f6f6f6;
        // height: 16px;
        span{
          display: inline-block;
          width: 3px;
          height: 24px;
          background: #fe9712;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>